import { Currency, PointCurrency, RewardType } from 'Constants/enums';
import { EMPTY_REWARD, invalidCurrencyValues } from 'Constants/currency';
import * as Money from 'Utils/money';
import * as PointsUtils from 'Utils/points';
import { getQuantityString } from 'Utils/general';
import { hasMoney, hasPoints, isRewardEmpty } from 'Utils/models/Reward';
import { isZero } from 'Utils/points';
import { Money as MoneyType } from 'Types/money.interface';
import { Price } from 'Types/price.interface';
import { PaymentValue } from 'Types/paymentValue.interface';
import { Reward } from 'Types/reward.interface';
import { Point } from 'Types/point.interface';
import { UseCases } from 'Utils/globalPermissions';
import { AppConfig } from 'Utils/appConfig';
import { getFormattedNumber } from "Utils/formatters";

export interface FormatPrice {
  mainPrice: Reward;
  altPrice1: Reward;
  altPrice2: Reward;
}

export const getCurrencyByCountry = (countryCode: string) => {
  switch (countryCode.toUpperCase()) {
    case 'RO':
      return Currency.RON;
    case 'CA':
      return Currency.CAD;
    case 'AD':
    case 'AT':
    case 'BE':
    case 'CY':
    case 'DE':
    case 'EE':
    case 'ES':
    case 'FI':
    case 'FR':
    case 'GR':
    case 'IE':
    case 'IT':
    case 'LT':
    case 'LU':
    case 'LV':
    case 'MC':
    case 'ME':
    case 'MT':
    case 'NL':
    case 'PT':
    case 'SK':
    case 'SI':
    case 'SM':
    case 'VA':
    case 'XK':
      return Currency.EUR;
    default:
      return Currency.USD;
  }
};

// !IMPORTANT this function is not 100% as the one from android
export const formatMoney = (
  money?: MoneyType,
  formal: boolean | undefined = false,
  defaultValue: string = EMPTY_REWARD,
  enforceDecimal?: boolean,
) => {
  if (!money || !money.amount) {
    return defaultValue;
  }

  const amountAsDec: string | any = Money.getAmountAsBigDecimal(money.amount, enforceDecimal);
  const formattedAmount = getFormattedNumber({ value: amountAsDec, enforceDecimal });
  let amount = money.currency !== Currency.None
    ? `${formattedAmount} ${money.currency}`
    : formattedAmount;

  if (!formal) {
    amount = amount.replace('[.]0*$', '');
  }

  return amount;
};

export const formatReward = (reward?: Reward, defaultValue= EMPTY_REWARD, enforceDecimal?: boolean) => {
  let moneyStr = null;
  let pointStr = '';

  if (!reward) {
    return defaultValue;
  }

  if (reward.money) {
    moneyStr = formatMoney(reward.money, false, defaultValue, enforceDecimal);
  }

  if (reward.points && PointsUtils.sumOfAbs(reward.points) > 0) {
    const stars = PointsUtils.getNormalizedPoints(reward.points, PointCurrency.Star);
    const systemTokens = PointsUtils.getNormalizedPoints(reward.points, PointCurrency.SystemToken);

    if (stars > 0) {
      pointStr = stars === 1 ? 'one star' : `${stars} stars`;
    }

    if (systemTokens > 0) {
      pointStr = systemTokens === 1 ? '1 Token' : `${systemTokens.toLocaleString()} Tokens`;
    }

    const customPoints : string[] = [];
    Object.keys(reward.points).forEach((key) => {
      const currency = key as PointCurrency;
      const points = reward.points
        ? PointsUtils.getPointsAmount(reward.points, currency)
        : 0;

      if (currency === PointCurrency.Point) {
        customPoints.push(points === 1 ? '1 point' : `${points.toLocaleString()} points`);
        return;
      }

      if (![PointCurrency.Star, PointCurrency.SystemToken].includes(currency)) {
        customPoints.push(`${points.toLocaleString()} ${currency}`);
      }
    });

    if (customPoints?.length > 0) {
      const joinedCustomPoints = customPoints.join(' + ');
      pointStr += pointStr ? ` + ${joinedCustomPoints}` : joinedCustomPoints;
    }
  }

  const arr = [];

  if (moneyStr && moneyStr.trim()) {
    arr.push(moneyStr);
  }

  if (pointStr && pointStr.trim()) {
    arr.push(pointStr);
  }

  if (reward?.vouchers && Object.keys(reward?.vouchers)?.length > 0) {
    Object.values(reward?.vouchers).forEach((voucher) => {
      arr.push(`1 ${voucher.gist}`);
    });
  }

  return arr.join(' + ');
};

export function formatPrice(price?: Price | null, rewardType?: RewardType) {
  if (!price) {
    return '';
  }

  const parts = [];
  const separator = rewardType === RewardType.Both ? ' + ' : ' / ';

  if (price.mainPrice && Object.keys(price.mainPrice).length) {
    const reward = formatReward(price.mainPrice, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice1 && Object.keys(price.altPrice1).length) {
    const reward = formatReward(price.altPrice1, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice2 && Object.keys(price.altPrice2).length) {
    const reward = formatReward(price.altPrice2, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  return parts.join(separator);
}

const multiplyMoney = (money: MoneyType, quantity: number): PaymentValue | null => {
  if (!money || !quantity) {
    return null;
  }

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    amount: money.amount * quantity,
    currency: money.currency,
  };
};

const multiplyPts = (point: Point, quantity: number): Point | null => {
  if (!point || !quantity) {
    return null;
  }

  if (isZero(point)) {
    return point;
  }

  return {
    amount: Number(point.amount) * quantity,
    currency: point.currency,
  };
};

const multiplyPoints = (points?: Record<string, Point> | null, quantity?: number): Record<string, Point> | null => {
  const result = {};

  if (!points || !quantity) {
    return null;
  }

  if (!Object.keys(points).length) {
    return points;
  }

  Object.keys(points).forEach((pointType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    result[pointType] = multiplyPts(points[pointType], quantity);
  });

  return result;
};

export const multiply = (reward?: Reward, quantity?: number) => {
  const result: {
    points: any;
    money?: MoneyType;
  } = {
    points: undefined,
  };

  if (!reward || !quantity) {
    return null;
  }

  if (isRewardEmpty(reward)) {
    return reward;
  }

  if (hasMoney(reward)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    result.money = multiplyMoney(reward.money, quantity);
  }
  if (hasPoints(reward)) {
    result.points = multiplyPoints(reward.points, quantity);
  }

  return result;
};

export function formatPoints(pts: Point, skipEmpty = false, shortTexts = false) {
  if (!pts || !Object.keys(pts).length) {
    return '';
  }

  finalFormatPoints(Number(pts.amount), pts.currency, skipEmpty, shortTexts);
}

/**
 * format points
 * @param {int} amount
 * @param {string} currency
 * @param {boolean} skipEmpty
 * @param {boolean} shortTexts
 * @returns {*}
 */
export function finalFormatPoints(amount: number, currency: string, skipEmpty: boolean, shortTexts: boolean) {
  if (amount === 0 && skipEmpty) {
    return '';
  }

  amount *= PointsUtils.multiplier(currency); // eslint-disable-line

  switch (PointsUtils.normalizeCurrency(currency)) {
    case PointCurrency.Star:
      return getQuantityString('lbl_stars_count_one', 'lbl_stars_count_other', amount);
    case PointCurrency.Point: {
      const labelIds: string[] = shortTexts
        ? ['lbl_pts_quantity_one', 'lbl_pts_quantity_other']
        : ['lbl_points_quantity_one', 'lbl_points_quantity_other'];
      return getQuantityString(...labelIds, amount);
    }
    case PointCurrency.Min:
      return getQuantityString('lbl_mins_quantity_one', 'lbl_mins_quantity_other', amount);
    case PointCurrency.Invite:
      return getQuantityString('lbl_invites_quantity_one', 'lbl_invites_quantity_other', amount);
    default:
      return `${amount} ${currency}`;
  }
}

export const isValidCurrency = (currency: Currency) =>
  currency && !invalidCurrencyValues.includes(currency);

export const getMainPointCurrency = () => {
  if (UseCases.campaigns === AppConfig?.useCase) {
    return PointCurrency.SystemToken;
  }

  return PointCurrency.Point;
};
