import { lazy } from 'react';
import CustomLazyLoader from './CustomLazyLoader';

export const AsyncSendInvites = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'SendInvitesPage' */ 'Containers/SendInvitesPage')),
);

export const AsyncChatComponent = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ChatComponent' */ 'Components/ChatsPage')),
);

export const AsyncBountiesPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BountiesPage' */ 'Containers/BountiesPage')),
);

export const AsyncReservationsHomePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ReservationsHomePage' */ 'Containers/ReservationsHomePage')),
);

export const AsyncMyProductsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MyProductsPage' */ 'Containers/MyProductsPage')),
);

export const AsyncBountyDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BountyDetailsPage' */ 'Containers/BountyDetailsPage')),
);

export const AsyncBountyResponseDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BountyResponseDetailsPage' */ 'Containers/BountyResponseDetailsPage')),
);

export const AsyncUserAccountPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MyAccountPageContainer' */ 'Containers/UserAccountPage')),
);

export const AsyncCompanyAccountPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CompanyAccountPage' */ 'Containers/CompanyAccountPage')),
);

export const AsyncLeaderboardPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'LeaderboardPage' */ 'Containers/LeaderboardPage')),
);

export const AsyncDefaultUserProfilePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'DefaultUserProfilePage' */ 'Containers/DefaultUserProfilePage')),
);

export const AsyncSentsContainer = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'SentsPage' */ 'Containers/SentsPage')),
);

export const AsyncReportsContainer = lazy(
  () => import(/* webpackChunkName: 'ReportsContainer' */ 'Containers/ReportsPage'),
);

export const AsyncProductsDetailContainer = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ProductsDetailContainer' */ 'Containers/ProductDetailsPage')),
);

export const AsyncCompanyProductsSents = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CompanyProductsSents' */ 'Containers/CompanyProductsSents')),
);

export const AsyncUploadProductsContainer = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'UploadProductsContainer' */ 'Containers/ProductsUpload')),
);

export const AsyncCompanyPinContainer = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'VerifyPin' */ 'Containers/VerifyPin')),
);

export const AsyncInvitationPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'Invitation' */ 'Containers/Invitation')),
);

export const AsyncOnBoardingLayout = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OnBoardingLayout' */ 'Containers/Onboarding')),
);

export const AsyncEmployerPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'EmployerPage' */ 'Containers/EmployerCreationPage')),
);

export const AsyncEmployerAuth = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'EmployerAuth' */ 'Containers/EmployerAuthPage')),
);

export const AsyncEmployerSuccess = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'EmployerSuccess' */ 'Containers/EmployerSuccessPage')),
);

export const AsyncNotFoundPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'NotFoundPage' */ 'Components/NotFoundPage')),
);

export const AsyncMerchantLanding = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MerchantLanding' */ 'Containers/MerchantLandingPage')),
);

export const AsyncMerchantSignup = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MerchantSignup' */ 'Containers/MerchantSignup')),
);

export const AsyncMerchantRegisterCompany = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MerchantRegisterCompany' */ 'Containers/MerchantRegisterCompany')),
);

export const AsyncMerchantUrlCompany = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MerchantUrlCompany' */ 'Containers/MerchantCompanyUrlPage')),
);

export const AsyncBountyHashtagResults = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BountyHashtagResults' */ 'Containers/BountyHashtagResults')),
);

export const AsyncCartPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncCartPage' */ 'Containers/CartPage')),
);

export const AsyncGroupsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'GroupsPage' */ 'Containers/GroupsPage')),
);

export const AsyncGroupProfilePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'GroupProfilePage' */ 'Containers/GroupProfilePage')),
);

export const AsyncOrderDetails = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OrderList' */ 'Containers/OrderDetails')),
);

export const AsyncOrderDetailsShop = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OrderDetailsShop' */ 'Containers/OrderDetailsShop')),
);

export const AsyncOrderDetailsCampaigns = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OrderDetailsCampaigns' */ 'Containers/OrderDetailsCampaigns')),
);

export const AsyncOrderPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OrdersPage' */ 'Containers/OrdersPage')),
);

export const AsyncOrderReceivedPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'OrdersReceivedPage' */ 'Containers/OrdersReceivedPage')),
);

export const AsyncUserAddressesPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'UserAddressesPage' */ 'Containers/UserAddressesPage')),
);

export const AsyncUserCreditCardsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'UserCards' */ 'Containers/UserCreditCardsPage')),
);

export const AsyncCreateOrganization = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CreateOrganization' */ 'Containers/CreateOrganization')),
);

export const AsyncMerchantProfilePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MerchantProfilePage' */ 'Containers/MerchantProfilePage')),
);

export const AsyncPromotionsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'PromotionsPage' */ 'Containers/PromotionsPage')),
);

export const AsyncPromotionDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'PromotionDetailsPage' */ 'Containers/PromotionDetailsPage')),
);

export const AsyncBadgeCollectionsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BadgeCollectionsPage' */ 'Containers/BadgeCollectionsPage')),
);

export const AsyncBadgeCollectionDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'BadgeCollectionDetailsPage' */ 'Containers/BadgeCollectionDetailsPage')),
);

export const AsyncNotificationCreatePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'NotificationCreatePage' */ 'Containers/NotificationCreatePage')),
);

export const AsyncApplicationsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ApplicationsPage' */ 'Containers/ApplicationsPage')),
);

export const AsyncApplicationDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ApplicationDetailsPage' */ 'Containers/ApplicationDetailsPage')),
);

export const AsyncApplicantsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CandidatesPage' */ 'Containers/ApplicantsPage')),
);

export const AsyncCandidatesPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CandidatesPage' */ 'Containers/CandidatesPage')),
);

export const AsyncCompanyUsersPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CompanyUsersPage' */ 'Containers/CompanyUsersPage')),
);

export const AsyncMatchesPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MatchesPage' */ 'Containers/MatchesPage')),
);

export const AsyncMatchDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MatchDetailsPage' */ 'Containers/MatchDetailsPage')),
);

export const AsyncHomePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'HomePage' */ 'Containers/HomePage')),
);

export const AsyncTerminal = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'Terminal' */ 'Containers/TerminalPage')),
);

export const AsyncUsersPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'Users' */ 'Containers/UsersPage')),
);

export const AsyncJobsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'JobsPage' */ 'Containers/JobsPage')),
);

export const AsyncJobDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'JobDetailsPage' */ 'Containers/JobDetailsPage')),
);

export const AsyncJobEditPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'JobEditPage' */ 'Containers/JobEditPage')),
);

export const AsyncProfileEditPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'JobEditPage' */ 'Containers/EditProfilePage')),
);

export const AsyncUserProfilePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'UserProfile' */ 'Containers/UserProfilePage')),
);

export const AsyncClientsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ClientsPage' */ 'Containers/ClientsPage')),
);

export const AsyncClientHomePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ClientHomePage' */ 'Containers/ClientHomePage')),
);

export const AsyncVeraHomePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'VeraHomePage' */ 'Containers/VeraHomePage')),
);

export const AsyncCampaignsHomePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CampaignsHomePage' */ 'Containers/CampaignsHomePage')),
);

export const AsyncDocumentsSearchPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'DocumentsSearchPage' */ 'Containers/DocumentsSearchPage')),
);

export const AsyncAiChatBotPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AiChatBotPage' */ 'Containers/AiChatBotPage')),
);

export const AsyncTalkToSupportPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'TalkToSupportPage' */ 'Containers/TalkToSupportPage')),
);

export const AsyncPurchaseTokensPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'PurchaseTokensPage' */ 'Containers/PurchaseTokensPage')),
);

export const AsyncCustomTokensPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CustomTokensPage' */ 'Containers/CustomTokensPage')),
);

export const AsyncClientLocationsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ClientLocationsPage' */ 'Containers/ClientLocationsPage')),
);

export const AsyncCompanyMembersPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CompanyMembersPage' */ 'Containers/CompanyMembersPage')),
);

export const AsyncBannersPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncBannersPage' */ 'Containers/BannersPage')),
);

export const AsyncAnalyticsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AnalyticsPage' */ 'Containers/AnalyticsPage')),
);

export const AsyncLocationDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'LocationDetailsPage' */ 'Containers/LocationDetailsPage')),
);

export const AsyncClientDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientDetailsPage' */ 'Containers/ClientDetailsPage')),
);

export const AsyncClientEmbedPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientEmbedPage' */ 'Containers/ClientEmbedPage')),
);

export const AsyncClientShopItemsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientShopItemsPage' */ 'Containers/ClientShopItemsPage')),
);

export const AsyncClientOnboardingPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientOnboardingPage' */ 'Containers/ClientOnboardingPage')),
);

export const AsyncClientSupportSettingsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientSupportSettingsPage' */ 'Containers/ClientSupportSettingsPage')),
);

export const AsyncClientSeoSettingsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientSeoSettingsPage' */ 'Containers/ClientSeoSettingsPage')),
);

export const AsyncClientSchedulePage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientSchedulePage' */ 'Containers/ClientSchedulePage')),
);

export const AsyncClientCheckoutPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientCheckoutPage' */ 'Containers/ClientCheckoutPage')),
);

export const AsyncClientBrandingPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientBrandingPage' */ 'Containers/ClientBrandingPage')),
);

export const AsyncClientLayoutAndSortingPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientLayoutAndSortingPage' */ 'Containers/ClientLayoutAndSortingPage')),
);

export const AsyncClientLabResultsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientLabResultsPage' */ 'Containers/ClientLabResultsPage')),
);

export const AsyncClientGeneralPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientGeneralPage' */ 'Containers/ClientGeneralPage')),
);

export const AsyncClientCartPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientCartPage' */ 'Containers/ClientCartPage')),
);

export const AsyncClientEmailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientEmailsPage' */ 'Containers/ClientEmailsPage')),
);

export const AsyncClientChangeLogPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientChangeLogPage' */ 'Containers/ClientChangeLogPage')),
);

export const AsyncClientKioskPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientKioskPage' */ 'Containers/ClientKioskPage')),
);

export const AsyncClientSiteSettingsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientSiteSettingsPage' */ 'Containers/ClientSiteSettingsPage')),
);

export const AsyncClientInventorySettingsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncClientInventorySettingsPage' */ 'Containers/ClientInventorySettingsPage')),
);

export const AsyncMyShopPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'MyShopPage' */ 'Containers/MyShopPage')),
);

export const AsyncReservationsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncReservationsPage' */ 'Containers/ReservationsPage')),
);

export const AsyncReservationDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncReservationDetailsPage' */ 'Containers/ReservationDetailsPage')),
);

export const AsyncReviewsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncReviewsPage' */ 'Containers/ReviewsPage')),
);

export const AsyncShopPolicyDetailsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'ShopPolicyDetailsPage' */ 'Containers/ShopPolicyDetailsPage')),
);

export const AsyncCampaignsPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CampaignsPage' */ 'Containers/CampaignsPage')),
);

export const AsyncVisitApp = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AsyncVisitApp' */ 'Containers/VisitApp')),
);

export const AsyncCreateCampaignPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'CreateCampaignPage' */ 'Containers/CreateCampaignPage')),
);

export const AsyncAdminLoginPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AdminLoginPage' */ 'Containers/AdminAuthenticationPage')),
);

export const AsyncAuthenticationPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AuthenticationPage' */ 'Containers/AuthenticationPage')),
);

export const AsyncAuthenticationOnboardingPage = lazy(
  () => CustomLazyLoader(() => import(/* webpackChunkName: 'AuthenticationOnboardingPage' */ 'Containers/AuthenticationOnboardingPage')),
);
