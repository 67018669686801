import { deleteApiData, getApiData, patchApiData, postApiData, putApiData } from 'Services/Api';
import { formatApiRoute } from 'Utils/api';
import { CompanyType, SyncComponentType } from 'Constants/enums';
import { ListResponse } from 'Types/listResponse.interface';
import { AddClientResponse, Client, ClientInfo } from 'Types/client.interface';
import { InventoryAreaInterface } from 'Types/inventoryArea.interface';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from 'Utils/firebase';
import { CreateClientLocation } from "Types/clientLocation.interface";
import {
  CLIENT_DETAILS_API,
  CLIENT_LOCATIONS_API,
  CLIENT_SETTINGS_AUDIT_LOG_ROUTE,
  CLIENTS,
  UPDATE_CLIENT_LOCATION_API
} from 'Constants/apiRoutes';
import { ChangeLog } from "Types/changeLog.interface";

const ClientsService = {
  async getClientsList(queryParams?: { orgType: CompanyType }): Promise<ListResponse<Client>> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENTS,
      params: {},
      queryParams: queryParams || {},
    });
    return getApiData<ListResponse<Client>>(formattedRoute);
  },

  async getClientDetails(clientId: string): Promise<Client> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_DETAILS_API,
      params: { clientId },
      queryParams: {},
    });

    return getApiData<Client>(formattedRoute);
  },

  async getClientInventoryAreas(clientId: string): Promise<{ list: InventoryAreaInterface[] }> {
    const formattedRoute = formatApiRoute({
      endpoint: `${CLIENTS}/:clientId/inventoryAreas`,
      params: { clientId },
      queryParams: {},
    });

    return getApiData<{ list: InventoryAreaInterface[] }>(formattedRoute);
  },

  async addClient(client: { clientName: string; orgType: string; clientInfo?: Partial<ClientInfo> }): Promise<AddClientResponse> {
    return postApiData(CLIENTS, client);
  },

  async updateClient(client: Partial<Client>, clientId: string): Promise<void> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_DETAILS_API,
      params: { clientId },
      queryParams: {},
    });

    return patchApiData<void>(formattedRoute, client);
  },

  async resyncClient(companyId: string, component = SyncComponentType.All): Promise<void> {
    const formattedRoute = formatApiRoute({
      endpoint: `${CLIENTS}/:companyId/sync/:component`,
      params: { companyId, component },
      queryParams: {},
    });

    return postApiData<void>(formattedRoute);
  },

  async uploadLogoUrl(companyId: string, picture: Blob) {
    const storageRef = ref(storage, `branding/logo/${companyId}/logoUrl`);
    const uploadResponse = await uploadBytes(storageRef, picture);
    return getDownloadURL(uploadResponse?.ref);
  },

  async deleteLogoUrl(companyId: string) {
    const storageRef = ref(storage, `branding/logo/${companyId}/logoUrl`);
    return deleteObject(storageRef);
  },

  async deleteClient(clientId: string) {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_DETAILS_API,
      params: { clientId },
      queryParams: {},
    });
    return deleteApiData(formattedRoute);
  },

  async restoreClient(companyId: string) {
    const formattedRoute = formatApiRoute({
      endpoint: `${CLIENTS}/:companyId/restore`,
      params: { companyId },
      queryParams: {},
    });
    return putApiData(formattedRoute);
  },

  async addLocation(clientId: string, location: CreateClientLocation) {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_LOCATIONS_API,
      params: { clientId },
      queryParams: {},
    });
    return postApiData(formattedRoute, location);
  },

  async updateLocation(clientId: string, location: CreateClientLocation) {
    const formattedRoute = formatApiRoute({
      endpoint: UPDATE_CLIENT_LOCATION_API,
      params: { clientId },
      queryParams: {},
    });
    return putApiData(formattedRoute, location);
  },

  async getAuditLogs(clientId: string, queryParams: Record<string, string | number>): Promise<ListResponse<ChangeLog>> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_SETTINGS_AUDIT_LOG_ROUTE,
      params: { clientId },
      queryParams,
    });

    return getApiData<ListResponse<ChangeLog>>(formattedRoute);
  },
};

export default ClientsService;
