import * as ROUTES from 'Constants/routes';
import { generateLink } from 'Utils/link';
import { AppConfig, getApplicationConfig } from 'Utils/appConfig';
import { CompanyType } from 'Constants/enums';
import { UseCases } from 'Utils/globalPermissions';

const { useCase = '' } = getApplicationConfig();

export const V2HOME = {
  menu_title: 'sidebar.v2home',
  menu_icon: 'home',
  path: ROUTES.V2_HOME_ROUTE,
  child_routes: null,
};

export const USER_SENTS = {
  menu_title: 'sidebar.sents',
  menu_icon: 'icon-send-3',
  path: ROUTES.USER_SENTS_ROUTE,
  child_routes: null,
};

export const GROUPS = {
  menu_title: 'sidebar.groups',
  menu_icon: 'icon-people',
  path: ROUTES.GROUPS_ROUTE,
  child_routes: null,
};

export const MESSAGES = {
  id: 'message',
  menu_title: 'sidebar.messages',
  menu_icon: 'chat',
  path: ROUTES.CHAT_ROUTE,
  child_routes: null,
};

export const MY_ACCOUNT = {
  menu_title: 'sidebar.myAccount',
  menu_icon: 'account_balance_wallet',
  path: ROUTES.MY_ACCOUNT_ROUTE,
  child_routes: null,
};

export const COMPANY_ACCOUNT = {
  menu_title: 'sidebar.companyAccount',
  menu_icon: 'account_balance',
  path: ROUTES.COMPANY_ACCOUNT_ROUTE,
  child_routes: null,
};

export const ORDERS = {
  menu_title: 'sidebar.orders',
  menu_icon: 'history',
  path: ROUTES.ORDER_LIST_ROUTE,
  child_routes: null,
};

export const RECEIVED_ORDERS = {
  menu_title: 'sidebar.receivedOrders',
  menu_icon: 'shopping_basket',
  path: ROUTES.RECEIVED_ORDER_LIST_ROUTE,
  child_routes: null,
};

export const PRODUCTS = {
  menu_title: 'sidebar.stream',
  menu_icon: 'icon-home-3',
  path: ROUTES.PRODUCTS_ROUTE,
  child_routes: null,
};

export const COMPANY_SENTS = {
  menu_title: 'sidebar.companySents',
  menu_icon: 'icon-send-3',
  path: ROUTES.COMPANY_SENTS_ROUTE,
  child_routes: null,
};

export const REPORTS = {
  menu_title: 'sidebar.reports',
  menu_icon: 'monitoring',
  path: ROUTES.REPORTS_ROUTE,
  child_routes: null,
};

export const LEADERBOARD = {
  menu_title: 'sidebar.leaderboard',
  menu_icon: 'leaderboard',
  path: ROUTES.LEADERBOARD_ROUTE,
  child_routes: null,
};

export const DASHBOARD_RESERVATIONS = {
  menu_title: 'sidebar.dashboard',
  menu_icon: 'dashboard',
  path: ROUTES.DASHBOARD_RESERVATIONS_ROUTE,
  child_routes: null,
};

export const PROMOTIONS = {
  menu_title: 'sidebar.promotions',
  menu_icon: 'sell',
  path: ROUTES.PROMOTIONS_ROUTE,
  child_routes: null,
};

export const BADGES = {
  menu_title: 'sidebar.badges',
  menu_icon: 'shield',
  path: ROUTES.BADGE_COLLECTIONS_ROUTE,
  child_routes: null,
};

export const CREATE_NOTIFICATION = {
  menu_title: 'sidebar.notificationsManagement',
  menu_icon: 'notification_add',
  path: ROUTES.CREATE_NOTIFICATION_ROUTE,
  child_routes: null,
};

export const JOBS = {
  menu_title: 'sidebar.jobs',
  menu_icon: 'icon-briefcase',
  path: ROUTES.JOBS_ROUTE,
  child_routes: null,
};

export const APPLICANTS = {
  menu_title: 'sidebar.applicants',
  menu_icon: 'icon-user-octagon',
  path: ROUTES.APPLICANTS_ROUTE,
  child_routes: null,
};

export const APPLICATIONS = {
  menu_title: 'sidebar.applications',
  menu_icon: 'icon-task-square',
  path: ROUTES.APPLICATIONS_ROUTE,
  child_routes: null,
};

export const CANDIDATES = {
  menu_title: 'sidebar.candidates',
  menu_icon: 'icon-profile-2-user',
  path: ROUTES.CANDIDATES_ROUTE,
  child_routes: null,
};

export const USER_MANAGEMENT = {
  menu_title: 'sidebar.users',
  menu_icon: 'icon-book',
  path: ROUTES.USER_MANAGEMENT_ROUTE,
  child_routes: null,
};

export const AMBASSADOR = {
  menu_title: 'sidebar.ambassador',
  menu_icon: 'icon-location-tick',
  path: generateLink(ROUTES.COMPANY_USERS, { typeAlias: 'ambassadors' }),
  child_routes: null,
};

export const PARTNER = {
  menu_title: 'sidebar.partner',
  menu_icon: 'icon-medal',
  path: generateLink(ROUTES.COMPANY_USERS, { typeAlias: 'partners' }),
  child_routes: null,
};

export const EMPLOYER = {
  menu_title: 'sidebar.employer',
  menu_icon: 'icon-briefcase',
  path: generateLink(ROUTES.COMPANY_USERS, { typeAlias: 'employers' }),
  child_routes: null,
};

export const MATCH_DETAILS = {
  menu_title: 'sidebar.matches',
  menu_icon: 'sports_soccer',
  path: generateLink(ROUTES.MATCHES_ROUTE),
  child_routes: null,
};

export const TERMINAL = {
  menu_title: 'Terminal',
  menu_icon: 'icon-user-octagon',
  path: ROUTES.TERMINAL_ROUTE,
  child_routes: null,
};

export const SEND_INVITES = {
  menu_title: 'sidebar.sendInvites',
  menu_icon: 'icon-share2',
  path: ROUTES.SEND_INVITES_ROUTE,
  child_routes: null,
};

export const CLIENTS = {
  menu_title: 'sidebar.clients',
  menu_icon: 'store',
  path: ROUTES.CLIENTS_ROUTE,
  child_routes: null,
};

export const REVIEWS = {
  menu_title: 'sidebar.reviews',
  menu_icon: 'rate_review',
  path: ROUTES.REVIEWS_ROUTE,
  child_routes: null,
};

export const SHOP_SETTINGS = {
  menu_title: 'sidebar.shopSettings',
  menu_icon: 'settings',
  path: ROUTES.SHOP_SETTINGS_ROUTE,
  has_arrow: true,
  is_expendable: true,
};

const getShopSettingsOption = (routePermissions) => ({
  ...SHOP_SETTINGS,
  child_routes: [
    {
      menu_title: 'sidebar.general',
      path: ROUTES.SHOP_SETTINGS_GENERAL_ROUTE,
    },
    {
      menu_title: 'sidebar.embed',
      path: ROUTES.SHOP_SETTINGS_EMBED_ROUTE,
    },
    {
      menu_title: 'sidebar.branding',
      path: ROUTES.SHOP_SETTINGS_BRANDING_ROUTE,
    },
    {
      menu_title: 'sidebar.layoutAndSorting',
      path: ROUTES.SHOP_SETTINGS_LAYOUT_AND_SORTING_ROUTE,
    },
    {
      menu_title: 'sidebar.labResults',
      path: ROUTES.SHOP_SETTINGS_LAB_RESULTS_ROUTE,
    },
    {
      menu_title: 'sidebar.onboarding',
      path: ROUTES.SHOP_SETTINGS_ONBOARDING_ROUTE,
    },
    {
      menu_title: 'sidebar.seo',
      path: ROUTES.SHOP_SETTINGS_SEO_ROUTE,
    },
    {
      menu_title: 'sidebar.shopItems',
      path: ROUTES.SHOP_SETTINGS_SHOP_ITEMS_ROUTE,
    },
    {
      menu_title: 'sidebar.support',
      path: ROUTES.SHOP_SETTINGS_SUPPORT_ROUTE,
    },
    {
      menu_title: 'sidebar.cart',
      path: ROUTES.SHOP_SETTINGS_CART_ROUTE,
    },
    {
      menu_title: 'sidebar.checkout',
      path: ROUTES.SHOP_SETTINGS_CHECKOUT_ROUTE,
    },
    {
      menu_title: 'sidebar.hoursOfOperation',
      path: ROUTES.SHOP_SETTINGS_HOURS_OF_OPERATION_ROUTE,
    },
    {
      menu_title: 'sidebar.emails',
      path: ROUTES.SHOP_SETTINGS_EMAILS_ROUTE,
    },
    {
      menu_title: 'sidebar.inventory',
      path: ROUTES.SHOP_INVENTORY_SETTINGS_ROUTE,
    },
    {
      menu_title: 'sidebar.changeLog',
      path: ROUTES.SHOP_SETTINGS_CHANGE_LOG_ROUTE,
    },
    ...(routePermissions.canSeeSiteSettings
      ? [{
        menu_title: 'sidebar.siteSettings',
        path: ROUTES.SHOP_SETTINGS_SITE_SETTINGS_ROUTE,
      }]
      : []),
    ...(routePermissions.canSeeKioskSettings
      ? [{
        menu_title: 'sidebar.kiosks',
        path: ROUTES.SHOP_SETTINGS_KIOSKS_ROUTE,
      }]
      : []),
  ],
});

export const SHOP_LOCATIONS = {
  menu_title: 'sidebar.locations',
  menu_icon: 'icon-location',
  path: ROUTES.SHOP_LOCATIONS_ROUTE,
  child_routes: null,
};

export const COMPANY_MEMBERS = {
  menu_title: [UseCases.shop, UseCases.vip].includes(useCase) ? 'sidebar.users' : 'sidebar.members',
  menu_icon: 'group',
  path: ROUTES.COMPANY_MEMBERS_ROUTE,
  child_routes: null,
};

export const ANALYTICS = {
  menu_title: 'sidebar.analytics',
  menu_icon: 'icon-chart-21',
  path: ROUTES.ANALYTICS_ROUTE,
  child_routes: null,
};

export const AI_CHAT_BOT = {
  menu_title: 'sidebar.chatAiBot',
  menu_icon: 'icon-messages-3',
  path: ROUTES.CHAT_AI_ROUTE,
  child_routes: null,
};

export const DOCUMENTS_SEARCH = {
  menu_title: 'sidebar.docSearch',
  menu_icon: 'icon-search',
  path: ROUTES.DOCUMENTS_SEARCH_ROUTE,
  child_routes: null,
};

export const PURCHASE_TOKENS = {
  menu_title: 'sidebar.purchaseTokens',
  menu_icon: 'generating_tokens',
  path: ROUTES.PURCHASE_TOKENS_ROUTE,
  child_routes: null,
};

export const TALK_TO_SUPPORT = {
  menu_title: 'sidebar.support',
  menu_icon: 'contact_support',
  path: ROUTES.TALK_TO_SUPPORT_ROUTE,
};

export const MY_PRODUCTS = {
  menu_title: 'sidebar.myProducts',
  menu_icon: 'icon-send-3',
  path: ROUTES.MY_PRODUCTS_ROUTE,
  child_routes: null,
};

export const CREATE_CAMPAIGN = {
  menu_title: 'sidebar.create',
  menu_icon: AppConfig?.images?.appLogoText,
  path: ROUTES.CREATE_CAMPAIGN_ROUTE,
  child_routes: null,
};

export const MY_SHOP = {
  menu_title: 'sidebar.myShop',
  menu_icon: 'storefront',
  path: ROUTES.MY_SHOP_ROUTE,
  child_routes: null,
};

export const CUSTOM_TOKENS = {
  menu_title: 'sidebar.customTokens',
  menu_icon: 'build_circle',
  path: ROUTES.CUSTOM_TOKENS_ROUTE,
  child_routes: null,
};

const sidebarVisibilityPriority = {
  [V2HOME.path]: 900,
  [PURCHASE_TOKENS.path]: 800,
  [CLIENTS.path]: 800,
  [CUSTOM_TOKENS.path]: 700,
  [SHOP_LOCATIONS.path]: 700,
  [DASHBOARD_RESERVATIONS.path]: 700,
  [MY_SHOP.path]: 700,
  [MY_ACCOUNT.path]: 600,
  [COMPANY_ACCOUNT.path]: 600,
  [SHOP_SETTINGS.path]: 600,
  [COMPANY_MEMBERS.path]: 500,
  [ORDERS.path]: 500,
  [USER_MANAGEMENT.path]: 400,
  [PROMOTIONS.path]: 300,
  [RECEIVED_ORDERS.path]: 300,
  [DOCUMENTS_SEARCH.path]: 300,
  [REVIEWS.path]: 200,
  [REPORTS.path]: 200,
  [AI_CHAT_BOT.path]: 200,
  [MESSAGES.path]: 200,
  [TALK_TO_SUPPORT.path]: 100,
};

const sortSidebarOptions = (sidebarOptions, optionsVisibilityPriority) => sidebarOptions.sort((a, b) => {
  const priorityA = optionsVisibilityPriority[a.path] || 0;
  const priorityB = optionsVisibilityPriority[b.path] || 0;

  return priorityB - priorityA; // Sort in descending order based on priority
});

export function sidebarNavLinks({ routePermissions, orgTypes }) {
  const organizationsItems = [];

  if (routePermissions.canSeeCompanies) {
    orgTypes.forEach((type) => {
      if (itemsByOrgType[type]) {
        organizationsItems.push(itemsByOrgType[type]);
      }
    });
  }

  const sidebarOptions = [
    V2HOME,
    ...(routePermissions.canSeeApplicants ? [APPLICANTS] : []),
    ...(routePermissions.canSeeResponses ? [CANDIDATES] : []),
    ...(routePermissions.canSeeUserManagement ? [USER_MANAGEMENT] : []),
    ...(routePermissions.canSeeResponses ? [APPLICATIONS] : []),
    ...(routePermissions.canSeeAiBot ? [AI_CHAT_BOT] : []),
    ...(routePermissions.canSeeDocSearch ? [DOCUMENTS_SEARCH] : []),
    ...(routePermissions.canSeeTokenPackages ? [PURCHASE_TOKENS] : []),
    ...(routePermissions.canSeeMessages ? [MESSAGES] : []),
    ...(routePermissions.canSeeCustomTokens ? [CUSTOM_TOKENS] : []),
    ...(routePermissions.canSeeCompanyMembers ? [COMPANY_MEMBERS] : []),
    ...(routePermissions.canSeeJobs ? [JOBS] : []),
    ...(routePermissions.canSeeMatch ? [MATCH_DETAILS] : []),
    ...(routePermissions.canSeeMyAccount ? [MY_ACCOUNT] : []),
    ...(routePermissions.canSeeCompanyAccount ? [COMPANY_ACCOUNT] : []),
    ...(routePermissions.canSeeMarket ? [ORDERS] : []),
    ...(routePermissions.canSeeRecvOrders ? [RECEIVED_ORDERS] : []),
    ...(routePermissions.canHaveManyInviteLinks ? [SEND_INVITES] : []),
    ...(routePermissions.canSeeClients ? [CLIENTS] : []),
    ...(routePermissions.canSeeShopSettings ? [getShopSettingsOption(routePermissions)] : []),
    ...(routePermissions.canSeeLocations ? [SHOP_LOCATIONS] : []),
    ...(routePermissions.canSeeGroups ? [GROUPS] : []),
    ...(routePermissions.canSeeAnalytics ? [ANALYTICS] : []),
    ...(routePermissions.canCreateBounty || routePermissions.canSeeSents ? [USER_SENTS] : []),
    ...(routePermissions.canNotify ? [CREATE_NOTIFICATION] : []),
    ...(routePermissions.canCreateBadge ? [BADGES] : []),
    ...(routePermissions.canUploadProducts ? [COMPANY_SENTS] : []),
    ...(routePermissions.canSeeReports ? [REPORTS] : []),
    ...(routePermissions.canSeePromotions ? [PROMOTIONS] : []),
    ...(routePermissions.canSeeLeaderBoard ? [LEADERBOARD] : []),
    ...(routePermissions.canSeeReservations ? [DASHBOARD_RESERVATIONS] : []),
    ...(routePermissions.canSeeShell ? [TERMINAL] : []),
    ...(routePermissions.canSeeMyProducts ? [MY_PRODUCTS] : []),
    ...(routePermissions.canSeeShop ? [MY_SHOP] : []),
    ...(routePermissions.canSeeReviews ? [REVIEWS] : []),
    ...organizationsItems,
  ];

  if (AppConfig.id !== 'joblio') {
    const sortedOptions = sortSidebarOptions(sidebarOptions, sidebarVisibilityPriority);

    return {
      category2: sortedOptions,
      category3: [],
      create: [
        ...(routePermissions.canCreateCampaign ? [CREATE_CAMPAIGN] : []),
      ],
    };
  }

  return {
    category2: [
      V2HOME,
      ...(routePermissions.canSeeApplicants ? [APPLICANTS] : []),
      ...(routePermissions.canSeeResponses ? [CANDIDATES] : []),
      ...(routePermissions.canSeeUserManagement ? [USER_MANAGEMENT] : []),
      ...(routePermissions.canSeeResponses ? [APPLICATIONS] : []),
      ...(routePermissions.canSeeAiBot ? [AI_CHAT_BOT] : []),
      ...(routePermissions.canSeeDocSearch ? [DOCUMENTS_SEARCH] : []),
      ...(routePermissions.canSeeTokenPackages ? [PURCHASE_TOKENS] : []),
      ...(routePermissions.canSeeCustomTokens ? [CUSTOM_TOKENS] : []),
      ...(routePermissions.canSeeMessages ? [MESSAGES] : []),
      ...(routePermissions.canSeeCompanyMembers ? [COMPANY_MEMBERS] : []),
      ...(routePermissions.canSeeJobs ? [JOBS] : []),
      ...(routePermissions.canSeeMatch ? [MATCH_DETAILS] : []),
      ...(routePermissions.canSeeMyAccount ? [MY_ACCOUNT] : []),
      ...(routePermissions.canSeeMarket ? [ORDERS] : []),
      ...(routePermissions.canSeeRecvOrders ? [RECEIVED_ORDERS] : []),
      ...(routePermissions.canHaveManyInviteLinks ? [SEND_INVITES] : []),
      ...(routePermissions.canSeeClients ? [CLIENTS] : []),
      ...(routePermissions.canSeeShopSettings ? [SHOP_SETTINGS] : []),
      ...(routePermissions.canSeeLocations ? [SHOP_LOCATIONS] : []),
      ...(routePermissions.canSeeGroups ? [GROUPS] : []),
      ...(routePermissions.canSeeAnalytics ? [ANALYTICS] : []),
      ...(routePermissions.canSeeMyProducts ? [MY_PRODUCTS] : []),
    ],
    category3: [
      ...(routePermissions.canCreateBounty || routePermissions.canSeeSents ? [USER_SENTS] : []),
      ...organizationsItems,
      ...(routePermissions.canNotify ? [CREATE_NOTIFICATION] : []),
      ...(routePermissions.canCreateBadge ? [BADGES] : []),
      ...(routePermissions.canUploadProducts ? [COMPANY_SENTS] : []),
      ...(routePermissions.canSeeReports ? [REPORTS] : []),
      ...(routePermissions.canSeePromotions ? [PROMOTIONS] : []),
      ...(routePermissions.canSeeLeaderBoard ? [LEADERBOARD] : []),
      ...(routePermissions.canSeeShell ? [TERMINAL] : []),
    ],
    create: [],
  };
}

const itemsByOrgType = {
  [CompanyType.Ambassador]: AMBASSADOR,
  [CompanyType.Partner]: PARTNER,
  [CompanyType.Employer]: EMPLOYER,
};
