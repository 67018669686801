import { getOptionCount } from 'Utils/productOptions';
import {
  addToMyShoppingCart,
  saveShoppingCartCoupon,
  saveShoppingCartDeliveryAddress,
  saveShoppingCartNegotiatedAmount,
  saveShoppingCartPaymentInfo,
  saveShoppingCartRedeemTokens,
  updateProductQuantity,
} from 'Services/ShoppingCart';
import { getCart } from 'Store/cart/selectors';
import { getProductPrices } from 'Utils/product';

export const ACTIONS = {
  SET_DELIVERY_ADDRESS: 'cartReducer/SET_DELIVERY_ADDRESS',
  SET_PAYMENT_METHOD: 'cartReducer/SET_PAYMENT_METHOD',
  SET_COUPON: 'cartReducer/SET_COUPON',
  SET_HAS_COUPON_APPLIED: 'cartReducer/SET_HAS_COUPON_APPLIED',
  SET_REDEEM_TOKENS: 'cartReducer/SET_REDEEM_TOKENS',
  SET_NEGOTIATED_AMOUNT: 'cartReducer/SET_NEGOTIATED_AMOUNT',
  SET_CART_PRODUCTS: 'cartReducer/SET_CART_PRODUCTS',
  INCREASE_TOTAL_PRODUCTS_QUANTITY: 'cartReducer/INCREASE_TOTAL_PRODUCTS_QUANTITY',
  DECREASE_TOTAL_PRODUCTS_QUANTITY: 'cartReducer/DECREASE_TOTAL_PRODUCTS_QUANTITY',
  SET_TOTAL_PRODUCTS_QUANTITY: 'cartReducer/SET_TOTAL_PRODUCTS_QUANTITY',
  CLEAR_CART: 'cartReducer/CLEAR_CART',
};

export const setDeliveryAddress = (payload) => (dispatch) => {
  saveShoppingCartDeliveryAddress(payload);
  dispatch({ type: ACTIONS.SET_DELIVERY_ADDRESS, payload });
};

export const setPaymentMethod = (payload) => (dispatch) => {
  saveShoppingCartPaymentInfo(payload);
  dispatch({ type: ACTIONS.SET_PAYMENT_METHOD, payload });
};

export const setCoupon = (payload) => (dispatch) => {
  saveShoppingCartCoupon(payload);
  dispatch({ type: ACTIONS.SET_COUPON, payload });
};

export const setNegotiatedAmount = (payload) => (dispatch) => {
  saveShoppingCartNegotiatedAmount(payload);
  dispatch({ type: ACTIONS.SET_NEGOTIATED_AMOUNT, payload });
};

export const setProducts = (payload) => ({
  type: ACTIONS.SET_CART_PRODUCTS,
  payload,
});

export const setHasCouponAppliedSuccessfully = (payload) => ({
  type: ACTIONS.SET_HAS_COUPON_APPLIED,
  payload,
});

export const clearCart = () => ({
  type: ACTIONS.CLEAR_CART,
});

export const increaseTotalProductsQuantity = () => ({
  type: ACTIONS.INCREASE_TOTAL_PRODUCTS_QUANTITY,
});

export const decreaseTotalProductsQuantity = (payload) => ({
  type: ACTIONS.DECREASE_TOTAL_PRODUCTS_QUANTITY,
  payload,
});

export const setTotalProductsQuantity = (payload) => ({
  type: ACTIONS.SET_TOTAL_PRODUCTS_QUANTITY,
  payload,
});

export const addToCart = ({ bounty, callback }) => (dispatch, getState) => {
  const state = getState();
  const product = bounty?.product;
  const { products } = getCart(state) || {};
  const optionCount = getOptionCount(product);

  if (!optionCount) {
    const { id } = bounty;
    const addedProduct = products
      ? Object.values(products).find(({ bountyId }) => bountyId === id)
      : null;

    console.log('product', product, 'products', products, 'addedProduct', addedProduct);

    dispatch(increaseTotalProductsQuantity());

    if (addedProduct) {
      updateProductQuantity({ productInfo: addedProduct, newQuantity: addedProduct.quantity + 1 });
    } else {
      addToMyShoppingCart({ productBounty: bounty, sku: null });
    }
  }

  callback({ optionCount });
};

export const setRedeemTokens = (payload) => (dispatch) => {
  saveShoppingCartRedeemTokens(payload);
  dispatch({ type: ACTIONS.SET_REDEEM_TOKENS, payload });
};
