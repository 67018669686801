import { Fee, ShippingFee } from "Types/shippingFees.interface";
import { Currency } from "Constants/enums";
import { extractNumberFromNumericFormat } from "Utils/numbers";

const formatAmount = (amount: string) => ({
  money: {
    amount: extractNumberFromNumericFormat(amount).toString(),
    currency: Currency.USD,
  },
});

export const formatShippingFeeForServer = (shippingFee: ShippingFee): ShippingFee => {
  const internationalFlatAmount = shippingFee?.internationalFee?.flatRate?.money?.amount;
  const internationalFreeAmount = shippingFee?.internationalFee?.freeOver?.money?.amount;
  const formattedDomesticFee: Record<string, Fee> = {};

  Object.entries(shippingFee?.domesticFees)?.forEach(([key, fee]) => {
    const domesticFlatAmount = fee?.flatRate?.money?.amount;
    const domesticFreeAmount = fee?.freeOver?.money?.amount;

    formattedDomesticFee[key] = {
      ...fee,
      freeOver: domesticFreeAmount ? formatAmount(domesticFreeAmount) : null,
      flatRate: domesticFlatAmount ? formatAmount(domesticFlatAmount) : null,
    };
  });

  return {
    domesticFees: formattedDomesticFee,
    internationalFee: {
      ...shippingFee?.internationalFee,
      flatRate: internationalFlatAmount ? formatAmount(internationalFlatAmount) : null,
      freeOver: internationalFreeAmount ? formatAmount(internationalFreeAmount) : null,
    }
  };
};
